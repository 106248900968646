<template>
  <div class="divBj" :style="'min-height:' + clientHeight + 'px'">
    <div class="divLog textBold indent fl">
      <div class="mgt10"><span>您好！</span></div>
      <div><span>欢迎加入中国债券</span></div>
      <div class="btmtop"></div>
    </div>
    <div class="from1">
      <div>
        <van-form :v-model="loginInfo" ref="formRef" @submit="tologinCheck">
          <van-cell-group inset>
            <div class="textBold fl">
              <span style="font-size: 20px; color: #606060">手机号</span>
            </div>
            <van-field
              v-model="loginInfo.Account"
              class="input1"
              type="number"
              name="用户名"
              placeholder="请填写您的手机号"
              :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <div class="textBold fl">
              <span style="font-size: 20px; color: #606060">密码</span>
            </div>
            <van-field
              v-model="loginInfo.Password"
              class="input1"
              :type="pwdType"
              name="密码"
              placeholder="请输入您的密码"
              :rules="[{ required: true, message: '请填写密码' }]"
              :right-icon="showEye"
              @click-right-icon="ShowPwd"
            />
            <!-- <div class="textBold fl">
              <span style="font-size: 20px; color: #606060">图形验证码</span>
            </div>
            <div class="capt1 indent fl">
              <van-row justify="space-between" align="center">
                <van-col span="14">
                  <van-field
                    v-model="loginInfo.Captcha"
                    class="input1"
                    name="验证码"
                    :rules="[{ required: true, message: '请填写验证码' }]"
                    placeholder="请输入图形验证码"
                  />
                </van-col>
                <van-col span="8">
                  <img class="image" :src="captInfo" @click="GetCode" />
                </van-col>
                <van-col span="2"></van-col>
              </van-row>
            </div> -->
          </van-cell-group>
          <div class="dir1">
            <van-divider style="color: #e3b6b9" />
          </div>
        </van-form>
      </div>

      <div class="div90">
        <van-row justify="space-betweend" align="center">
          <van-col span="10">
            <van-checkbox
              class="lCheck fl"
              shape="square"
              checked-color="#a92124"
              v-model="checked"
              @click="remePwd"
              >记住密码</van-checkbox
            >
          </van-col>
          <van-col span="7"> </van-col>
          <van-col span="7" @click="Public.toService">
            <div class="fr mgr10">
              <van-row justify="" align="center">
                <van-icon :name="icon1" size="16" color="#979797" />
                <span style="font-size: 16px; color: #979797">联系客服</span>
              </van-row>
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="textCenter">
        <van-button
          class="btn10"
          round="true"
          size="large"
          @click="checkSubmit"
        >
          <span style="font-size: 24px">立即登录</span>
        </van-button>
      </div>

      <div class="logininfo">
        <div style="font-size: 10px">
          <span style="font-size: 16px; color: #979797"
            >登录即代表您已阅读并同意</span
          >
          <div>
            <span
              style="font-size: 16px; color: #a92124"
              @click="toUserAgreementPage"
              >《用户协议》</span
            >
            <span style="font-size: 16px; color: #979797">与</span>
            <span
              style="font-size: 16px; color: #a92124"
              @click="toPrivacyPolicyPage"
              >《隐私政策》</span
            >
          </div>
        </div>

        <div class="btn20 mgt20" @click="toRegPage">
          <van-row align="center">
            <van-col span="12">
              <div class="fr mgl10">
                <span style="font-size: 16px; color: #979797">还没有账号</span>
              </div>
            </van-col>
            <van-col span="12" class="fl textCenter textBold">
              <span style="font-size: 16px; color: #a92124">立即注册</span>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import router from '../../router';
import Public from '@/public/public';
import { showLoadingToast } from 'vant';
import { v4 as uuidv4 } from 'uuid';

const clientHeight = ref('');
const loginInfo = ref({
  Account: '',
  Password: '',
  CaptchaId: '',
  Captcha: '',
});
const pwdType = ref('password');
const showEye = ref('closed-eye');
const click = ref(0);
const captInfo = ref('');
const checked = ref(true);
const formRef = ref(null);

const icon1 = require('../../assets/account/service.png');

const getHeight = () => {
  // 获取浏览器可视区域高度
  clientHeight.value = `${document.documentElement.clientHeight}`; //document.body.clientWidth;
  //console.log(self.clientHeight);
  window.onresize = function temp() {
    clientHeight.value = `${document.documentElement.clientHeight}`;
  };
};

const ShowPwd = () => {
  if (click.value % 2 == 0) {
    pwdType.value = '';
    showEye.value = 'eye-o';
  } else {
    pwdType.value = 'password';
    showEye.value = 'closed-eye';
  }
  click.value += 1;
};

const checkSubmit = () => {
  formRef.value.submit();
};

const tologinCheck = () => {
  if (loginInfo.value.Account == '' || loginInfo.value.Password == '') {
    showLoadingToast({
      message: '账号密码验证码不能为空',
      forbidClick: true,
    });
  } else {
    var toast = showLoadingToast({
      message: '登陆中...',
      forbidClick: true,
      duration: 0,
    });
    Public.Login({
      ...loginInfo.value,
    }).then((res) => {
      toast.close();
      if (res.data.code == 0) {
        switch (res.data.data.info) {
          case 'Succeed':
            var userToken = 'Bearer ' + res.data.data.token;
            localStorage.setItem('token', userToken);
            localStorage.setItem('retoken', res.data.data.retoken);
            localStorage.setItem('resetTime', res.data.data.expire);
            localStorage.setItem('setUserCode', loginInfo.value.Account);
            localStorage.setItem('setPassword', loginInfo.value.Password);
            if (window.AppBridge && window.AppBridge.writeToStorage) {
              window.AppBridge.writeToStorage(
                'setUserCode',
                loginInfo.value.Account
              );
              window.AppBridge.writeToStorage(
                'setPassword',
                loginInfo.value.Password
              );
            }
            localStorage.setItem('setRem', true);
            router.push('/Home');
            break;
        }
      } else {
        showLoadingToast({
          message: res.data.msg,
          forbidClick: true,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('retoken');
        localStorage.removeItem('resetTime');
      }
    });
  }
};
const remePwd = () => {
  if (checked.value != true) {
    loginInfo.value.Account = '';
    loginInfo.value.Password = '';
    loginInfo.value.Captcha = '';
    localStorage.removeItem('setUserCode');
    localStorage.removeItem('setPassword');
    localStorage.removeItem('setRem');
  }
};
const getDefaultSet = () => {
  loginInfo.value.Account = localStorage.getItem('setUserCode');
  loginInfo.value.Password = localStorage.getItem('setPassword');
  if (window.AppBridge && window.AppBridge.readFromStorage) {
    if (!loginInfo.value.Account) {
      loginInfo.value.Account = window.AppBridge.readFromStorage('setUserCode');
    }
    if (!loginInfo.value.Password) {
      loginInfo.value.Password =
        window.AppBridge.readFromStorage('setPassword');
    }
  }

  if (localStorage.getItem('setRem') == null) {
    checked.value = true;
  } else {
    checked.value = localStorage.getItem('setRem');
  }
};

const toRegPage = () => {
  router.push('/Register');
};
const toPrivacyPolicyPage = () => {
  router.push('/PrivacyPolicy');
};
const toUserAgreementPage = () => {
  router.push('/UserAgreement');
};

const loadData = () => {
  getHeight();
  getDefaultSet();
};

onMounted(() => {
  loadData();
});
</script>
<style scoped>
@import '../../css/public.css';

.divBj {
  width: 100%;
  height: 1em;
  margin: 0 auto;
}
.divLog {
  width: 100%;
  margin: 0 auto;
  padding-top: 1em;
  min-height: 4em;
  text-align: left;
  font-size: 2.2rem;
  color: #ffffff;
  background: linear-gradient(180deg, #db4a4f 0%, #a92124 100%);
}
.btmtop {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 15px;
  min-height: 1em;
  background-color: #fff;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}
.from1 {
  width: 100%;
  /* min-height: 15em; */
  margin: 0 auto;
  padding-top: -1em;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  z-index: 100;
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
.input1 {
  width: 100%;
  margin: 0 auto;
  color: #e3b6b9;
  font-size: medium;
  background-color: #fff;
  --van-field-input-text-color: #000;
}
.input2 {
  width: 90%;
  margin: 0 auto;
  color: #e3b6b9;
  font-size: medium;
  background-color: #fff;
  --van-field-input-text-color: #000;
}
.capt1 {
  width: 90%;
  margin: 0 auto;
  color: #e3b6b9;
  background-color: #fff;
  --van-field-input-text-color: #000;
}
.dir1 {
  width: 90%;
  margin: 0 auto;
  margin-top: -1em;
  color: #e3b6b9;
}
.logininfo {
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  color: #979797;
}
.btn10 {
  width: 95%;
  margin: 0 auto;
  margin-top: 2em;
  color: #fff;
  background: linear-gradient(270deg, #db4a4f 0%, #a92124 100%);
}
.btn20 {
  width: 60%;
  height: 25px;
  margin: 0 auto;
  padding-top: 0px;
  margin-top: 10px;
  color: #ff0000;
  border-radius: 15px;
}
.text {
  width: 90%;
  margin: 0 auto;
  color: #000;
}
.van-cell-group {
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
  background-color: #fff;
  opacity: 0.9;
}

.lCheck {
  margin-left: 18px;
  --van-checkbox-size: 18px;
  --van-checkbox-label-margin: 10px;
  --van-checkbox-label-color: #a92124;
}
</style>

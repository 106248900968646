import { createRouter, createWebHashHistory } from 'vue-router';
import axios from 'axios';
import store from '@/store/index';
import Login from '@/Views/Account/Login.vue';

const platform = '中国债券-';
const Register = () => import('@/Views/Account/Register');
const Invite = () => import('@/Views/Account/Invite');
const ChangePwd = () => import('@/Views/Account/ChangePwd');
const ChangeAssetPwd = () => import('@/Views/Account/ChangeAssetPwd');
const PwdSet = () => import('@/Views/Account/PwdSet');

const Home = () => import('@/Views/Index/home');
const Download = () => import('@/Views/Index/download');
const Dividends = () => import('@/Views/Index/dividends');
const Address = () => import('@/Views/Index/address');
const Blank = () => import('@/Views/Index/blank');

const Sign = () => import('@/Views/Business/sign');
const Invest = () => import('@/Views/Business/invest');
const Investtest = () => import('@/Views/Business/investtest');
const Withdraw = () => import('@/Views/Business/withdraw');
const WithdrawDetail = () => import('@/Views/Business/withdrawDetail');
const Audit = () => import('@/Views/Business/audit');
const TeamSalary = () => import('@/Views/Business/teamSalary');
const Payment = () => import('@/Views/Business/payment');
const Payment1 = () => import('@/Views/Business/payment1');
const Payment2 = () => import('@/Views/Business/payment2');
const Payment3 = () => import('@/Views/Business/payment3');
const Payment4 = () => import('@/Views/Business/payment4');
const PaymentUSDT = () => import('@/Views/Business/paymentUSDT');
const PaySuccess = () => import('@/Views/Business/paysuccess');
const InvestToken = () => import('@/Views/Business/investToken');
const Recharge = () => import('@/Views/Business/recharge');
const RechargeDetail = () => import('@/Views/Business/rechargeDetail');
const Welfare = () => import('@/Views/Business/welfare.vue');
const Application = () => import('@/Views/Business/application.vue');

const NewsPage = () => import('@/Views/Inform/newsPage');
const NewsDetail = () => import('@/Views/Inform/newsDetail');
const AboutCompany = () => import('@/Views/Inform/aboutCompany');
const Aboutus = () => import('@/Views/Inform/aboutus');
const Service = () => import('@/Views/Inform/service');
const Update = () => import('@/Views/Inform/update');
const PrivacyPolicy = () => import('@/Views/Inform/privacyPolicy');
const UserAgreement = () => import('@/Views/Inform/userAgreement');
const ChatGroup = () => import('@/Views/Inform/chatGroup');

const Mine = () => import('@/Views/Bill/mine');
const Team = () => import('@/Views/Bill/team');
const Bank = () => import('@/Views/Bill/bank');
const Bill = () => import('@/Views/Bill/bill');
const Cert = () => import('@/Views/Bill/cert');
const Order = () => import('@/Views/Bill/order');
const Conversion = () => import('@/Views/Bill/conversion');
const Overage = () => import('@/Views/Bill/overage');
const Yeb = () => import('@/Views/Bill/yeb');

const StockDeal = () => import('@/Views/Activity/stockDeal');
const NetworkFusion = () => import('@/Views/Activity/networkFusion');
const StockInherit = () => import('@/Views/Activity/stockInherit');
const SweepHistory = () => import('@/Views/Activity/sweepHistory');
const SweepDetails = () => import('@/Views/Activity/sweepDetails');
const TokenTransaction = () => import('@/Views/Activity/tokenTransaction');
const TokenRecord = () => import('@/Views/Activity/tokenRecord');
const InvitEvent = () => import('@/Views/Activity/invitEvent');
const InvitHistory = () => import('@/Views/Activity/invitHistory');
const TeamAwards = () => import('@/Views/Activity/teamAwards');
const TeamAwards3 = () => import('@/Views/Activity/teamAwards3');

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      component: Login,
      meta: {
        title: platform + '首页',
      },
    },
    {
      path: '/Login',
      component: Login,
      meta: {
        title: platform + '首页',
      },
    },
    {
      path: '/Register',
      component: Register,
      meta: {
        title: platform + '注册',
      },
    },
    {
      path: '/Invite',
      component: Invite,
      meta: {
        title: platform + '邀请好友',
      },
    },
    {
      path: '/UserAgreement',
      component: UserAgreement,
      meta: {
        title: platform + '用户协议',
      },
    },
    {
      path: '/PrivacyPolicy',
      component: PrivacyPolicy,
      meta: {
        title: platform + '隐私政策',
      },
    },
    {
      path: '/Address',
      component: Address,
      meta: {
        title: platform + '地址管理',
      },
    },
    {
      path: '/AddressList',
      component: () => import('@/Views/Index/addressList.vue'),
      meta: {
        title: platform + '地址管理',
      },
    },
    {
      path: '/InvitEvent',
      component: InvitEvent,
      meta: {
        title: platform + '迎新年庆春节',
      },
    },
    {
      path: '/InvitHistory',
      component: InvitHistory,
      meta: {
        title: platform + '领取记录',
      },
    },
    {
      path: '/InvitOpt',
      component: () => import('@/Views/Activity/invitOpt.vue'),
      meta: {
        title: platform + '兑换贡献值',
      },
    },
    {
      path: '/ChatGroup',
      component: ChatGroup,
      meta: {
        title: platform + '官方群聊',
      },
    },
    {
      path: '/Withdraw',
      component: Withdraw,
      meta: {
        title: platform + '提现',
      },
    },
    {
      path: '/Recharge',
      component: Recharge,
      meta: {
        title: platform + '充值',
      },
    },
    {
      path: '/RechargeDetail',
      component: RechargeDetail,
      meta: {
        title: platform + '充值明细',
      },
    },
    {
      path: '/Payment',
      component: Payment,
      meta: {
        title: platform + '支付',
      },
    },
    {
      path: '/Payment1',
      component: Payment1,
      meta: {
        title: platform + '支付',
      },
    },
    {
      path: '/Payment2',
      component: Payment2,
      meta: {
        title: platform + '支付',
      },
    },
    {
      path: '/Payment3',
      component: Payment3,
      meta: {
        title: platform + '支付',
      },
    },
    {
      path: '/Payment4',
      component: Payment4,
      meta: {
        title: platform + '支付',
      },
    },
    {
      path: '/Application',
      component: Application,
      meta: {
        title: platform + '保障房申请人信息',
      },
    },
    {
      path: '/PaymentUSDT',
      component: PaymentUSDT,
      meta: {
        title: platform + 'USDT支付',
      },
    },
    {
      path: '/PaySuccess',
      component: PaySuccess,
      meta: {
        title: platform + '支付成功',
      },
    },
    {
      path: '/WithdrawDetail',
      component: WithdrawDetail,
      meta: {
        title: platform + '提现记录',
      },
    },
    {
      path: '/ChangePwd',
      component: ChangePwd,
      meta: {
        title: platform + '登录密码管理',
      },
    },
    {
      path: '/ChangeAssetPwd',
      component: ChangeAssetPwd,
      meta: {
        title: platform + '登录密码管理',
      },
    },
    {
      path: '/PwdSet',
      component: PwdSet,
      meta: {
        title: platform + '密码管理',
      },
    },
    {
      path: '/Update',
      component: Update,
      meta: {
        title: platform + '密码管理',
      },
    },
    {
      path: '/Home',
      component: Home,
      meta: {
        title: platform + '首页',
      },
    },
    {
      path: '/Download',
      component: Download,
      meta: {
        title: platform + '下载',
      },
    },
    {
      path: '/Audit',
      component: Audit,
      meta: {
        title: platform + '实名认证',
      },
    },
    {
      path: '/Sign',
      component: Sign,
      meta: {
        title: platform + '签到',
      },
    },
    {
      path: '/Invest',
      component: Invest,
      meta: {
        title: platform + '养老金',
      },
    },
    {
      path: '/InvestToken',
      component: InvestToken,
      meta: {
        title: platform + '代币产品',
      },
    },
    {
      path: '/Investtest',
      component: Investtest,
      meta: {
        title: platform + '国债',
      },
    },
    {
      path: '/Dividends',
      component: Dividends,
      meta: {
        title: platform + '领取养老金',
      },
    },
    {
      path: '/TeamSalary',
      component: TeamSalary,
      meta: {
        title: platform + '推广工资',
      },
    },
    {
      path: '/NewsPage',
      component: NewsPage,
      meta: {
        title: platform + '新闻',
      },
    },
    {
      path: '/NewsDetail',
      component: NewsDetail,
      meta: {
        title: platform + '新闻详情',
      },
    },
    {
      path: '/Aboutus',
      component: Aboutus,
      meta: {
        title: platform + '玩法介绍',
      },
    },
    {
      path: '/Service',
      component: Service,
      meta: {
        title: platform + '客服',
      },
    },
    {
      path: '/AboutCompany',
      component: AboutCompany,
      meta: {
        title: platform + '公司简介',
      },
    },
    {
      path: '/Mine',
      component: Mine,
      meta: {
        title: platform + '我的',
      },
    },
    {
      path: '/Investtest',
      component: Investtest,
      meta: {
        title: platform + '体验区',
      },
    },
    {
      path: '/Team',
      component: Team,
      meta: {
        title: platform + '我的团队',
      },
    },
    {
      path: '/Bank',
      component: Bank,
      meta: {
        title: platform + '银行账号',
      },
    },
    {
      path: '/Bill',
      component: Bill,
      meta: {
        title: platform + '收益明细',
      },
    },
    {
      path: '/Order',
      component: Order,
      meta: {
        title: platform + '订单列表',
      },
    },
    {
      path: '/StockDeal',
      component: StockDeal,
      meta: {
        title: platform + '股权回购',
      },
    },
    {
      path: '/NetworkFusion',
      component: NetworkFusion,
      meta: {
        title: platform + '幸运福袋1+1',
      },
    },
    {
      path: '/SweepHistory',
      component: SweepHistory,
      meta: {
        title: platform + '抽奖历史',
      },
    },
    {
      path: '/SweepDetails',
      component: SweepDetails,
      meta: {
        title: platform + '开奖详情',
      },
    },
    {
      path: '/StockInherit',
      component: StockInherit,
      meta: {
        title: platform + '继承办理',
      },
    },
    {
      path: '/TokenTransaction',
      component: TokenTransaction,
      meta: {
        title: platform + '代币交易',
      },
    },
    {
      path: '/TokenRecord',
      component: TokenRecord,
      meta: {
        title: platform + '委托记录',
      },
    },
    {
      path: '/Cert',
      component: Cert,
      meta: {
        title: platform + '股权证书',
      },
    },
    {
      path: '/Lucky',
      component: () => import('@/Views/Business/lucky'),
      meta: {
        title: platform + '幸运抽奖',
      },
    },
    {
      path: '/Conversion',
      component: Conversion,
      meta: {
        title: platform + '资产转换',
      },
    },
    {
      path: '/TeamAwards',
      component: TeamAwards,
      meta: {
        title: platform + '扶持金补贴',
      },
    },
    {
      path: '/TeamAwards3',
      component: TeamAwards3,
      meta: {
        title: platform + '三期扶持金补贴',
      },
    },
    {
      path: '/Welfare',
      component: Welfare,
      meta: {
        title: platform + '国债福利',
      },
    },
    {
      path: '/Blank',
      component: Blank,
      meta: {
        title: platform + '官网',
      },
    },
    {
      path: '/Overage',
      component: Overage,
      meta: {
        title: platform + '余额宝',
      },
    },
    {
      path: '/Yeb',
      component: Yeb,
      meta: {
        title: platform + '余额宝',
      },
    },
  ],
});
// 路由拦截器
router.beforeEach((to, from, next) => {
  if (
    to.path === '/Login' ||
    to.path === '/404' ||
    to.path === '/500' ||
    to.path === '/Register' ||
    to.path === '/Service' ||
    to.path === '/Download' ||
    to.path === '/UserAgreement' ||
    to.path === '/PrivacyPolicy'
  ) {
    document.title = '中国债券';
    next();
  } else {
    const token = localStorage.getItem('token');
    if (token === null || token === '') {
      next('/Login');
    } else {
      if (to.meta.title) {
        document.title = to.meta.title;
      }
      next();
    }
  }
});
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    // 判断是否存在token，如果存在将每个页面header都添加token
    // if (token != null) {
    //   isRefreshTokenExpired(resetTime)
    //   if (resetTime < 864000) {
    //     if (!window.isReresh) {
    //       window.isReresh = true
    //       const refresh_token = localStorage.getItem('retoken')
    //       getRefreshToken(refresh_token).then(res => {
    //         window.isReresh = false
    //         switch (res.data.info) {
    //           case 'Succeed':
    //             {
    //                 isRefreshTokenExpired(res.data.expire)// 重新获取的token有效时间
    //                 const userToken = 'Bearer ' + res.data.token
    //                 store.commit('setToken', {
    //                   token: userToken
    //                 })
    //                 store.commit('setReToken', {
    //                   retoken: res.data.retoken
    //                 })
    //                 return Promise.resolve(res.data)
    //             }
    //           default:
    //             store.commit('delToken')
    //             break
    //         }
    //       })
    //     }
    //   } else {
    //     window.isReresh = false
    //   }
    // }
    const newToken = localStorage.getItem('token');
    config.headers.Authorization = newToken;
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          store.commit('delToken');
          router.replace({
            path: '/Login',
            query: { redirect: router.currentRoute.fullPath }, // 登录成功后跳入浏览的当前页面
          });
          break;
        case 504:
          router.replace({
            path: '/Login',
            query: { redirect: router.currentRoute.fullPath }, // 登录成功后跳入浏览的当前页面
          });
          break;
        case 502:
          router.replace({
            path: '/Login',
            query: { redirect: router.currentRoute.fullPath }, // 登录成功后跳入浏览的当前页面
          });
          break;
        case 404:
          router.replace({
            path: '/404',
            query: { redirect: router.currentRoute.fullPath }, // 登录成功后跳入浏览的当前页面
          });
          break;
        // case 500:
        //   router.replace({
        //     path: '/500',
        //     query: {redirect: router.currentRoute.fullPath}// 登录成功后跳入浏览的当前页面
        //   })
        //   break
      }
      // location. reload();
    }
    console.log(error);
    return Promise.reject(error.response.data);
  }
);

export default router;
